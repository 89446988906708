import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  "data-name": "Layer 1",
  viewBox: "0 0 154.16 90.57"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M42.48 45.96v1.55c.06 4.63.12 4.82.62 5.74a3.21 3.21 0 0 0 2.72 1.49 2.27 2.27 0 0 0 2.28-2 48 48 0 0 0 .25-5.12c0-2.84-.8-4.69-2.41-5.5-.84-.5-1.18-.5-4.64-.67v-7a11 11 0 0 0 1.3.06c2.48 0 4.27-1 4.76-2.77a13.3 13.3 0 0 0 .37-3.71c0-2.78-.8-4.08-2.59-4.08s-2.54 1.36-2.54 4.14v2.35h-9.38c.06-4.88.68-7.41 2.09-9.64 1.86-2.71 5.56-4.38 9.83-4.38 3.52 0 7.16 1.36 9.07 3.46s2.89 4.96 2.89 9.04c0 4.75-1.24 7.16-4.39 8.46 3.71 1.23 5.25 4.45 5.25 11 0 5.19-.86 8.16-3.09 10.44-2 2.1-5.31 3.27-9.2 3.27-4.94 0-8.83-1.85-10.75-5.06-1.11-1.85-1.66-4.82-1.66-8.53v-2.54Zm34.97-17.48c-.12-3.52-.93-4.75-3-4.75s-3 1.36-3 4v5.5c2-1.79 3.27-2.35 5.68-2.35a8.9 8.9 0 0 1 6.67 2.66c2.22 2.53 3 5.68 3 11.92 0 6.92-.81 10.56-2.84 13.15-1.61 2.11-5.31 3.46-9.33 3.46-5.81 0-9.7-2.47-11.36-7.22-.62-1.92-.87-4.45-.87-9.95v-9.38c0-7.78.19-10.06 1.17-12.53 1.55-4.2 5.69-6.61 11.25-6.61 4.38 0 7.84 1.54 9.88 4.5 1.42 2 1.91 4 1.91 7.6Zm0 13.9c0-3-.93-4.39-2.9-4.39s-3.15 1.49-3.15 4.39v7.6c0 3.46 1 5 3.27 5s2.78-1.11 2.78-4.51Zm13.49-13.84a12.57 12.57 0 0 1 2.1-7.59c2.16-2.9 6-4.57 10.5-4.57s7.9 1.67 10 4.63a14.75 14.75 0 0 1 2 7.53v21.38c0 3.64-1 6.79-2.78 8.7-2 2.11-5.87 3.46-9.82 3.46-4.69 0-8.71-1.85-10.38-4.69a16.9 16.9 0 0 1-1.66-8Zm15.38-.74c0-2.78-.93-4-2.91-4-2.22 0-3.15 1.36-3.15 4.75v22.23c0 2.72.93 4 3 4a2.93 2.93 0 0 0 2.59-1.3c.43-.75.5-1.11.5-3.52Zm31.16-16.99A10.61 10.61 0 1 1 126.87 0a10.65 10.65 0 0 1 10.61 10.81m-14.84-.1a4.23 4.23 0 1 0 4.23-4.33 4.32 4.32 0 0 0-4.23 4.33m-83.29 74.6a3 3 0 0 1-.59-.06C14.49 79.62 0 69 0 56.83c0-8.11 6.65-15.79 18.72-21.61a2.574 2.574 0 0 1 2.23 4.64c-10.19 4.92-15.8 10.94-15.8 17 0 9.32 13.65 18.51 34.77 23.4a2.57 2.57 0 0 1-.57 5.08Z" }, null, -1),
    _createElementVNode("path", { d: "m34.05 71.83 2.23 10.11-5.74 8.63 23.98-5.21zM94.1 88.49a2.572 2.572 0 0 1-.21-5.14c31.4-2.65 55.07-14 55.07-26.52 0-6.91-7.44-13.83-20.42-19a2.58 2.58 0 0 1 1.91-4.79c15.26 6.07 23.66 14.51 23.66 23.78 0 15.66-24.58 28.67-59.79 31.65Z" }, null, -1)
  ])))
}
export default { render: render }